<template>
    <div class="back-device text-center d-flex">
        <router-link
            v-if="$route.path != '/faq'"
            :to="{name: 'chose-device'}"
            class="align-self-center btn btn--lg"
        >
            <i class="icon icon--back"></i>Schimbă dispozitivul
        </router-link>
        <router-link
            v-if="$route.path == '/faq'"
            :to="{name: 'chose-device'}"
            class="align-self-center btn btn--lg"
        >
            <i class="icon icon--back"></i>Înapoi
        </router-link>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>