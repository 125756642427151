<template>
    <div class="back-device text-center d-flex">
        <div @click="back" class="align-self-center btn btn--lg">
            <i class="icon icon--back"></i>Înapoi
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        back: function() {
            this.$emit("backToVideos");
        }
    }
};
</script>

<style>
</style>